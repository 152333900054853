import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconAppAdviseComponent } from '../../icons/icon-app-advise.component';
import { IconAppEnergyComponent } from '../../icons/icon-app-energy.component';
import { IconAppFacilityComponent } from '../../icons/icon-app-facility.component';
import { IconAppInvoiceComponent } from '../../icons/icon-app-invoice.component';
import { IconAppSenseComponent } from '../../icons/icon-app-sense.component';
import { AppMenuComponent } from './app-menu.component';

@NgModule({
  declarations: [AppMenuComponent],
  imports: [
    CommonModule,
    IconAppAdviseComponent,
    IconAppEnergyComponent,
    IconAppFacilityComponent,
    IconAppInvoiceComponent,
    IconAppSenseComponent,
    RouterModule,
    TranslateModule,
  ],
  exports: [AppMenuComponent],
})
export class AppMenuModule {}
